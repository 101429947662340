
import { defineComponent } from "vue";
import { nextTick, onMounted, onUnmounted } from "vue";

export default defineComponent({
  name: "WellDoneBlock",
  props: {
    label: {
      type: String,
      default: () => "Jetzt einloggen und direkt Ausbildung anfragen!",
    },
  },
  setup() {
    onMounted(() => {
      nextTick(() => {
        const huraEl = document.getElementById("hura");
        huraEl?.classList.add("registration-success-img-wrapper");
      });
    });

    onUnmounted(() => {
      const huraEl = document.getElementById("hura");
      huraEl?.classList.remove("registration-success-img-wrapper");
    });

    return {};
  },
});
